<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('task.invoicing') }}</h3>
      </div>
    </div>
    <div class="relative border-b pb-2 pt-2">
      <AddToInvoiceDropDown
          :taskId="taskId"
          :createdAt="createdAt"
          :invoices="invoices"
      />
    </div>
  </div>
</template>

<script>
import AddToInvoiceDropDown from '@/components/DropDowns/AddToInvoiceDropDown/AddToInvoiceDropDown';
export default {
  name: 'InvoicingActions',
  components: {
    AddToInvoiceDropDown
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    invoices: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
