<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('link.links') }}</h3>
      </div>
    </div>

    <AddLinks
        v-if="!isPublicPage"
        @handleAddNewLink="$emit('handleAddNewLink', $event)"
    />
    <div v-for="link in  list" class="flex justify-between mt-3">
      <div>
        <a
            v-if="editableId !== link.id"
            :href="link.url"
            target="_blank"
        >
          <span class="font-normal block truncate w-290p">{{ link.name }}</span>
        </a>

        <div v-else class="flex items-center justify-start gap-1">
          <div class="w-full">
            <input
                type="text"
                v-model="link.url"
                class="h-formInput py-2 sm:text-sm rounded-md focus:outline-none focus:ring-opacity-0 block w-full focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300 px-3"
                :placeholder="$t('link.link')"
                @input="errorMessage = ''"
            />
          </div>
          <div class="w-full">
            <input
                type="text"
                :ref="'link_' + link.id + '_name'"
                v-model="link.name"
                class="h-formInput py-2 sm:text-sm rounded-md focus:outline-none focus:ring-opacity-0 block w-full focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300 px-3"
                :placeholder="$t('link.linkName')"
                @input="errorMessage = ''"
            />
          </div>

          <MainButton
              class="mr-1 saveBtn"
              :name="$t('global.buttons.save')"
              @clickAction="handleEditSubmit(link)"
          />

          <MainButton
              class="cancelBtn"
              :name="$t('global.buttons.cancel')"
              bgStyle="light"
              @clickAction="handleCancel"
          />
        </div>
      </div>
      <EditOrDeleteLink
          v-if="!isPublicPage"
          :item="link"
          :isEdit="!!editableId"
          @handleEdit="handleEdit"
          @handleOpenDeleteItemModal="$emit('handleDeleteLink', $event)"
      />
    </div>
  </div>
</template>

<script>
import AddLinks from '@/components/Links/AddLinks';
import EditOrDeleteLink from '@/components/Links/EditOrDeleteLink';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import _ from 'lodash';

export default {
  name: 'TaskLinks',
  props: {
    defaultMembers: {
      type: Array,
      'default': () => []
    },
    editable: {
      type: Boolean,
      'default': true
    },
    pageUpdateAction: {
      type: Boolean
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    },
    links: {
      type: Array,
      'default': () => []
    },
    label: {
      type: String,
      required: true
    }
  },
  components: {
    AddLinks,
    EditOrDeleteLink,
    MainButton
  },
  data() {
    return {
      searchValue: '',
      notUrl: false,
      editableId: 0,
      errorMessage: '',
      list:[]
    };
  },
  mounted() {
    this.list = _.cloneDeep(this.links);
  },
  watch:{
    links:function(newValue){
      this.list = _.cloneDeep(this.links);
    }
  },
  methods: {
    handleCancel(){
      this.list = _.cloneDeep(this.links);
      this.editableId = 0;
    },
    handleEdit(id) {
      this.editableId = id;
      process.nextTick(() => this.$refs['link_' + id + '_name'][0].focus());
    },
    handleEditSubmit(link, name) {
      if (this.validURL(link.url)){
       if (link.name) {
         this.errorMessage = '';

         this.$emit('handleEditLink', link, name);
         this.editableId = 0;
       } else if (link.name.length === 0) {
         this.errorMessage = [];
         this.toastMessage(this.$t('task.nameFieldIsRequired'), 'error')
       }
      } else {
        this.errorMessage = 'Invalid url';
        this.toastMessage(this.errorMessage, 'error');
      }
    },

    validURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
};
</script>

<style scoped>
  .saveBtn {
    font-size: 12px !important;
  }

  .cancelBtn {
    font-size: 12px !important;
  }
</style>
