<template>
  <div
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)"
      v-click-outside="closeDropdown"
      class="w-full"
  >
    <div>
      <div class="grid grid-cols-12 pt-3 pb-3 border-b border-gray-200">
       <div class="w-full col-span-11">
         <CheckListTitleLabel
             :list="list"
             :isInputEditable="isInputEditable"
             @handleInputChange="handleInputChange"
         />
       </div>
       <div v-if="!isPublicPage" class="col-span-1 flex justify-end list_area">
          <EditDeleteCheckListDropDown
              :list="list"
              v-if="showEditable || (selectedItem === String(list.id))"
              @handleSelectItem="handleSelectItem"
              @handleEditCheckList="handleEditCheckList"
              @handleAddNewItemDropDown="handleAddNewItemDropDown"
              @openDeleteCheckListModal="$emit('openDeleteCheckListModal', $event)"
          />
        </div>
      </div>
      <div v-if="list.items.length">
        <div class="mt-2">{{ list.items_done }}/{{ list.items_total }}</div>
        <ListItems
            :items="list.items"
            :listTitle="list.title"
            :isPublicPage="isPublicPage"
            @handleDeleteCheckListItem="handleDeleteCheckListItem"
            @handleUpdateCheckListItem="$emit('handleUpdateCheckListItem', $event)"
            @handleEditCheckListItemStatus="handleEditCheckListItemStatus"
        />
      </div>
    </div>
    <div v-if="isOpenAddNewItemDropDown" class="col-span-12">
      <CreateCheckListItemDropDown
          :checkListId="list.id"
          @handleCloseAddNewItemDropDown="isOpenAddNewItemDropDown = false"
          @handleCreateNewItem="$emit('handleCreateNewCheckListItem', $event)"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import EditDeleteCheckListDropDown
  from '@/components/CheckList/CheckListInputField/EditDeleteCheckListDropDown/EditDeleteCheckListDropDown';
import ListItems from '@/components/CheckList/CheckListsSection/CheckListItem/ListItems/ListItems';
import CreateCheckListItemDropDown
  from '@/components/CheckList/CheckListInputField/CheckListItems/CreateCheckListItemDropDown/CreateCheckListItemDropDown';
import CheckListTitleLabel
  from '@/components/CheckList/CheckListsSection/CheckListItem/CheckListTitleLabel/CheckListTitleLabel';

export default {
  name: 'CheckListItem',
  components: {
    ListItems,
    CheckListTitleLabel,
    CreateCheckListItemDropDown,
    EditDeleteCheckListDropDown
  },
  directives: {
    ClickOutside
  },
  props: {
    list: {
      type: Object,
      required: true
    },
    selectedItem: String,
    isPublicPage: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      isOpenEditAction: false,
      isOpenAddNewItemDropDown: false,
      showEditable: false,
      isInputEditable: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpenEditAction = false;
      this.isInputEditable = false;
    },
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleDeleteCheckListItem(itemId) {
      this.$emit('handleDeleteCheckListItem', { item: itemId, checkListId: this.list.id });
    },
    handleAddNewItemDropDown() {
      this.isOpenAddNewItemDropDown = true;
    },
    handleEditCheckList() {
      this.isInputEditable = true;
    },
    handleInputChange() {
      this.list.title.length && this.$emit('handleUpdateCheckList', { id: this.list.id, value: this.list.title });
    },
    handleEditCheckListItemStatus(event) {
      this.$emit('handleEditCheckListItemStatus', { item: event, checkListId: this.list.id });
    }
  }
}
</script>

<style scoped>
[contenteditable] {
  outline: 0 solid transparent;
}

.list_area:before {
  position: absolute;
  content: '';
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 0) 80%);
  width: 170px;
  height: 24px;
  top: 10px;
}
</style>
