<template>
  <div class="relative border-b pb-2 pt-2">
    <div v-if="!isOpen">
      <AddNewButton
          :name="$t('global.buttons.add_new')"
          @clickAction="isOpen = !isOpen"
      />
    </div>
    <div v-else class="flex items-center justify-start gap-1">
      <div class="w-full">
        <FormInput
            :value="title"
            inputName="title"
            :autofocus="true"
            :errorMessage="errorMessage"
            :placeholder="$t('global.inputs.checkLinksPlaceholder')"
            @handleInputChange="handleInputChange"
            @handleKeyUp="handleKeyUp"
            :classInputs="true"
        />
      </div>

      <div class="w-full">
        <FormInput
            :value="name"
            inputName="name"
            :autofocus="false"
            :errorMessage="errorMessage"
            :placeholder="$t('global.inputs.checkLinksNamePlaceholder')"
            @handleInputChange="handleInputChange"
            @handleKeyUp="handleKeyUp"
            :classInputs="true"

        />
      </div>
      <MainButton
          class="mr-1 addBtn"
          :name="$t('global.buttons.add')"
          @clickAction="handleAddNewLink"
      />
      <MainButton
          v-if="isOpen"
          class="cancelBtn"
          :name="$t('global.buttons.cancel')"
          bgStyle="light"
          @clickAction="isOpen = false"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';

export default {
  name: 'AddLinks',
  components: {
    AddNewButton,
    FormInput,
    MainButton
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpen: false,
      isCreateCheckList: false,
      errorMessage: [],
      title: '',
      name: ''
    };
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleInputChange(name, value) {
      this[name] = value
    },
    handleAddNewLink() {
      if (this.validURL(this.title)) {
        if (this.name.length) {
          this.errorMessage = [];
          this.$emit('handleAddNewLink', {
            url: this.title,
            name: this.name
          });
          this.closeDropdown();
          this.title = '';
          this.name = '';
        } else if (this.name.length === 0) {
          this.errorMessage = [];
          this.toastMessage(this.$t('task.nameFieldIsRequired'), 'error')
        }
      } else {
        this.errorMessage = [];
        this.toastMessage(this.$t('task.invalidUrl'), 'error')
      }
    },
    handleKeyUp(event, inputName) {
      this.handleInputChange(inputName, event.target.value);
      event.keyCode === 13 && this.handleAddNewLink();
    },

    validURL(str) {
      let url;

      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:';
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type })
    }
  }
};
</script>

<style scoped>
  .addBtn {
    font-size: 12px !important;
  }

  .cancelBtn {
    font-size: 12px !important;
  }
</style>
