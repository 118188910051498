<template>
    <div class="relative border-b pb-2 pt-2">
      <div v-if="!isOpen">
        <AddNewButton
            :name="$t('global.buttons.add_new')"
            @clickAction="isOpen = !isOpen"
        />
      </div>
      <div v-else class="flex items-center justify-start gap-1">
        <div class="w-full">
          <FormInput
              :value="title"
              :autofocus="true"
              :placeholder="$t('global.inputs.checkListPlaceholder')"
              @handleInputChange="handleInputChange"
              @handleKeyUp="handleKeyUp"
          />
        </div>
        <MainButton
            :name="$t('global.buttons.add')"
            @clickAction="handleAddNewCheckList"
        />
        <MainButton
            v-if="isOpen"
            :name="$t('global.buttons.cancel')"
            bgStyle="light"
            @clickAction="isOpen = false"
        />
      </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';

export default {
  name: 'AddCheckList',
  components: {
    AddNewButton,
    FormInput,
    MainButton
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpen: false,
      isCreateCheckList: false,
      title: ''
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleInputChange(inputName, value) {
      this.title = value;
    },
    handleAddNewCheckList() {
      if (this.title.length) {
        this.closeDropdown();
        this.$emit('handleAddNewCheckList', this.title);
        this.title = '';
      }
    },
    handleKeyUp(event, inputName) {
      this.handleInputChange(inputName, event.target.value);
      event.keyCode === 13 && this.handleAddNewCheckList();
    }
  }
}
</script>

<style scoped>

</style>
