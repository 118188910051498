<template>
  <div>
      <ul v-if="items.length" class="space-y-3 pt-2">
        <li v-for="label in items"  :key="label.id" class="flex justify-start">
          <a href="#" class="flex items-center space-x-3">
            <div>
              <div class="h-5 w-5 rounded-full bg-gray-200 flex items-center text-10 text-center" :class="label.color">
              </div>
            </div>
            <div class="mt-1 text-sm text-gray-900">{{ label.title }}</div>
          </a>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectOptionLabel',
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
