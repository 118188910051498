<template>
  <div>
    <DeleteItemModal
        v-if="showModal"
        :title="$t('checkList.deleteListItemModal.title')"
        :description="$t('checkList.deleteListItemModal.description')"
        @handleCloseModal="showModal = false"
        @handleConfirm="handleDeleteItem"
    />
    <ul class="mb-2">
      <li v-for="item in items" :key="item.id" class="block">
        <div class="relative w-full mr-2 mt-1.5 mb-1.5 inline-flex items-center py-0.5">
          <ListItem
              :item="item"
              :listTitle="listTitle"
              :isPublicPage="isPublicPage"
              @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
              @handleUpdateCheckListItem="$emit('handleUpdateCheckListItem', $event)"
              @handleEditCheckListItemStatus="$emit('handleEditCheckListItemStatus', $event)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import ListItem from '@/components/CheckList/CheckListsSection/CheckListItem/ListItems/ListItem/ListItem';

export default {
  name: 'ListItems',
  components: {
    ListItem,
    DeleteItemModal
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    listTitle: {
      type: String,
      'default': ''
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      showModal: false,
      selectedItem: {}
    }
  },
  methods: {
    handleOpenDeleteItemModal(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
    handleDeleteItem() {
      this.$emit('handleDeleteCheckListItem', this.selectedItem);
      this.showModal = false;
    }
  }
}
</script>

<style scoped>

</style>
