<template>
  <div class="relative inline-block text-left z-40" v-click-outside="closeDropdown">
    <div>
      <button
          @click.prevent="handleOpenDropDown"
          type="button"
          class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
          aria-expanded="true" aria-haspopup="true">
        <DropDownThreeDots />
      </button>
    </div>
    <div
        v-show="isOpenDropDown"
        class="shadow-lg mx-3 origin-top-right absolute right-1 w-48 mt-1 rounded-md 2xl:hover:z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
      <div @click.prevent="handleDeleteItem" class="py-1">
        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{{ $t('checkList.delete') }}</a>
      </div>
      <div @click.prevent="handleAddItem" class="py-1">
        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{{ $t('checkList.addItem') }}</a>
      </div>
      <div @click.prevent="handleEditCheckList" class="py-1">
        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{{ $t('checkList.edit') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
  name: 'EditDeleteCheckListDropDown',
  components: { DropDownThreeDots },
  directives: {
    ClickOutside
  },
  props: {
    list: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpenDropDown: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpenDropDown = false;
      this.$emit('handleSelectItem', '');
    },
    handleOpenDropDown() {
      this.isOpenDropDown = !this.isOpenDropDown;
      this.$emit('handleSelectItem', this.list.id);
    },
    handleDeleteItem() {
      this.$emit('openDeleteCheckListModal', this.list.id);
      this.isOpenDropDown = false;
    },
    handleAddItem() {
      this.$emit('handleAddNewItemDropDown');
      this.closeDropdown();
    },
    handleEditCheckList() {
      this.$emit('handleEditCheckList', this.list);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>

</style>
