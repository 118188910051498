<template>
  <div class="flex">
    <div class="flex">
      <div class="mr-2">
        <CheckListIcon />
      </div>
      <div
          v-if="!isInputEditable"
          class="h-auto truncate border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0">
        <span>{{ list.title }}</span>
      </div>
    </div>
    <input
        v-if="isInputEditable"
        class="textField_area w-full h-auto resize-none border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0"
        @change="$emit('handleInputChange')"
        v-model="list.title"
        autofocus="autofocus"
        ref="editableInput"
    />
  </div>
</template>

<script>
import CheckListIcon from '@/components/Icons/CheckListIcon';
export default {
  name: 'CheckListTitleLabel',
  components: { CheckListIcon },
  props: {
    isInputEditable: {
      type: Boolean,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  },
  watch: {
    isInputEditable(newValue) {
      newValue && process.nextTick(() => this.$refs.editableInput.focus());
    }
  }
}
</script>

<style scoped>

</style>
