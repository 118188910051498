<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('checkList.checklists') }}</h3>
      </div>
    </div>
    <AddCheckList
        v-if="!isPublicPage"
        @handleAddNewCheckList="$emit('handleAddNewCheckList', $event)"
    />
  </div>
</template>

<script>
import CheckLists from '@/components/CheckList/CheckListsSection/CheckLists/CheckLists';
import AddCheckList from '@/components/CheckList/AddCheckList/AddCheckList';

export default {
  name: 'CheckListInputField',
  components: {
    AddCheckList,
    CheckLists
  },
  props: {
    checklist: {
      type: Array
    },
    isPublicPage: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
