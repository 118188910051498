<template>
  <div
      v-if="isOpen"
      class="absolute z-50 min-h-150p mt-1 w-full bg-white max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm border border-gray-300">
    <div
        class="flex flex-col w-full items-center min-h-150p justify-center cursor-pointer">
      <label @click="driveIconClicked"  id="file-upload" ref="brDropzone" class="br_dropzone flex flex-col w-full items-center min-h-150p justify-center cursor-pointer relative cursor-pointer bg-white rounded-md font-medium">
        <div><GoogleCloudIcon /></div>
        <div><span class="mt-1 text-sm text-gray-900">{{ $t('main.attachYourFileFromGoogleDrive') }}</span></div>
      </label>
    </div>
  </div>
</template>

<script>

import GoogleCloudIcon from '@/components/Icons/GoogleCloudIcon';

export default {
  name: 'DropZone',
  components: {
    GoogleCloudIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      developerKey: process.env.VUE_APP_GOOGLE_API_KEY,
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      appId: process.env.VUE_APP_MIX_APP_ID,
      scope: process.env.VUE_APP_GOOGLE_SCOPE,
      pickerApiLoaded: false,
      oauthToken: null,
      docsView: null,
      picker: null
    }
  },
  mounted() {
    const gDrive = document.createElement('script');
    gDrive.setAttribute('type', 'text/javascript');
    gDrive.setAttribute('src', 'https://apis.google.com/js/api.js');
    document.head.appendChild(gDrive);
    this.getAccessToken()
  },
  methods: {
    getAccessToken(){
      this.oauthToken = localStorage.accessToken ?? null
    },
    async driveIconClicked() {
      if (!this.oauthToken){
        await gapi.load('auth2', () => {
          gapi.auth2.authorize(
              {
                client_id: this.clientId,
                scope: this.scope,
                immediate: false

              },
              this.handleAuthResult
          );
        });
      }
      gapi.load('picker', () => {
        this.pickerApiLoaded = true;
        this.createPicker();
      });
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        localStorage.accessToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        this.docsView = new google.picker.DocsView().setIncludeFolders(true).setSelectFolderEnabled(true);
        const picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .addView(this.docsView)
            .setOAuthToken(this.oauthToken)
            .setCallback(this.pickerCallback)
            .build();
        picker.setVisible(true);
      } else {
        this.toastMessage(this.$t('calendar.notification.google_account_login'), 'error')
      }
    },
    async pickerCallback(data) {
      if (data.action === google.picker.Action.PICKED) {
        if (data.action === 'picked') {
          this.$emit('handleAddGoogleAttachments', data.docs);
        }
      }
    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>

</style>
