<template>
    <div class="w-full" v-click-outside="closeDropdown">
        <div>
            <AddNewButton
                :name="$t('invoice.addNewInvoice')"
                @clickAction="isOpen = !isOpen"
            />
        </div>
        <div
            v-if="isOpen"
            class="origin-top-right mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
        >
            <div class="py-1">
                <div
                    v-if="invoices.length"
                    class="px-4 py-2 grid grid-cols-12"
                    :class="{
                        'opacity-50': !unsentInvoices.length
                    }"
                >
                    <div class="col-span-1">
                        <input
                            type="checkbox"
                            :checked="unsentInvoices.length"
                            :disabled="!unsentInvoices.length"
                            class="h-4 w-4 text-gray-600 focus:ring-0 rounded cursor-pointer rounded-full"
                        />
                    </div>
                    <div class="col-span-11">
                        <span class="select_option_name">
                            {{ createdAt + `(# ${invoices[this.invoices.length - 1].data.id})` }}
                        </span>
                    </div>
                </div>
                <div v-if="!unsentInvoices.length" class="px-4 py-2 grid grid-cols-12">
                    <div class="col-span-1">
                        <input
                            type="checkbox"
                            checked
                            class="h-4 w-4 text-gray-600 focus:ring-0 rounded cursor-pointer rounded-full"
                        />
                    </div>
                    <div class="col-span-11">
                        <span class="select_option_name">{{ $t('invoice.newInvoice') }}</span>
                    </div>
                </div>
                <div class="block px-4 py-2 w-full">
                    <MainButton
                        :name="$t('global.buttons.add')"
                        @clickAction="handleAddToTransmit"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';

export default {
    name: 'AddToInvoiceDropDown',
    components: {
        MainButton,
        FormInput,
        AddNewButton
    },
    props: {
        taskId: {
            type: String,
            required: true
        },
        createdAt: {
            type: String,
            required: true
        },
        invoices: {
            type: Array,
            required: true
        }
    },
    directives: {
        ClickOutside
    },
    data() {
        return {
            isOpen: false
        };
    },
    computed: {
        ...mapGetters([
            'addInvoiceSuccess',
            'addInvoiceError',
            'addInvoiceResponseMessage'
        ]),
        unsentInvoices() {
            return this.invoices.filter(invoice => invoice.data.status !== 'sent');
        }
    },
    watch: {
        addInvoiceSuccess(newValue) {
            newValue &&
                this.toastMessage(this.addInvoiceResponseMessage + this.invoices[this.invoices.length - 1].data.id);
        },
        addInvoiceError(newValue) {
            newValue &&
                this.toastMessage(this.addInvoiceResponseMessage, 'error');
        }
    },
    methods: {
        ...mapActions(['fetchInvoiceTransmit']),
        closeDropdown() {
            this.isOpen = false;
        },
        handleAddToTransmit() {
            this.fetchInvoiceTransmit({ id: this.taskId });
            this.closeDropdown();
        },
        toastMessage(message, type = 'success') {
            this.$toast.clear();
            this.$toast.open({ message, type });
        }
    }
};
</script>

<style scoped>
</style>
