<template>
  <div id="list-item" class="w-full grid grid-cols-12 lg:grid-cols-12   " v-click-outside="closeDropdown">
    <div v-if="!isInputEditable" class=" col-span-9  lg:col-span-8  flex items-center ">
      <div class="flex items-center">
        <input
            @change="handleEditCheckListItemStatus"
            type="checkbox"
            :checked="checked"
            :disabled="isPublicPage || isItemSent()"
            :class="{ 'cursor-default': isItemSent() }"
            class="h-4 w-4 text-gray-600 focus:ring-0	rounded col-span-2 cursor-pointer">
      </div>
      <div class=" col-span-9 ml-3.5 mr-2 p-0.5 text-sm text-gray-900 truncate">
        {{ dateTitle }}
      </div>
    </div>
    <div v-else class="col-span-9">
      <input
          class="textField_area w-full h-auto resize-none border-0 overflow-y-hidden text-sm text-gray-900 outline-none focus:ring-0 p-0"
          @change="handleInputChange"
          v-model="item.item"
          autofocus="autofocus"
          ref="editableInput"
      />
    </div>
    <ListItemActions
        v-if="!isInputEditable && !isPublicPage"
        :item="item"
        :listTitle="listTitle"
        :checked="!!checked"
        @handleEditCheckListItem="handleEditCheckListItem"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
        @handleEditCheckListItemStatus="$emit('handleEditCheckListItemStatus', $event)"
    />
  </div>
</template>

<script>
import ListItemActions
  from '@/components/CheckList/CheckListsSection/CheckListItem/ListItems/ListItemActions/ListItemActions';
import ClickOutside from 'vue-click-outside';
import AddInvoiceModal from '@/components/Modals/AddInvoiceModal/AddInvoiceModal';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'ListItem',
  components: {
    AddInvoiceModal,
    ListItemActions
  },
  directives: {
    ClickOutside
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    listTitle: {
      type: String,
      'default': ''
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      isInputEditable: false,
      checked: false
    }
  },
  mounted() {
    this.checked = this.item.done;
  },
  methods: {
    isItemSent() {
      return this.item.invoice_line?.invoice?.status === 'sent';
    },
    closeDropdown(element) {
      element.target.id !== 'edit-list-item' ? this.isInputEditable = false : null;
    },
    handleEditCheckListItemStatus() {
      this.$emit('handleEditCheckListItemStatus', { status: !!this.item.done, id: this.item.id });
      this.checked = !this.checked;
      this.item.done = !this.item.done;
    },
    handleEditCheckListItem() {
      this.isInputEditable = true;
      process.nextTick(() => this.$refs.editableInput.focus());
    },
    handleInputChange() {
      this.item.item.length && this.$emit('handleUpdateCheckListItem', { id: this.item.id, value: this.item.item });
    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type });
    }
  },
  computed: {
    dateTitle() {
      const texts = [];
      texts.push(this.item.invoice_line?.delivered_by.name);
      texts.push(Vue.filter('parseDateMain')(this.item.invoice_line?.delivered_at));
      texts.push(this.item.item);

      return texts.filter(item => item).join(', ');
    }
  }
}
</script>

<style scoped>
  .cursor-default {
    cursor: default;
  }
</style>
