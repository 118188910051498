<template>
    <div>
        <div
            v-if="!editable"
            class="text-sm font-medium text-gray-500 pb-2 border-b"
        >
            {{ placeholder }}
        </div>

        <div
            v-else
            class="w-full flex items-start justify-start flex-col gap-2"
        >
            <label class="text-sm font-medium text-gray-500">{{ placeholder }}</label>

            <div
                v-if="editable"
                class="w-full relative"
                v-click-outside="closeDropdown"
            >
                <div class="w-full">
                    <input
                        @click="toggleDropdown"
                        v-model="q"
                        type="text"
                        autocomplete="off"
                        class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
                    <FormInputArrowIcon/>
                </div>
                <div
                    class="absolute w-full rounded-md bg-white"
                >
                    <div v-if="isOpen && filteredOptions.length">
                        <ul
                            class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300"
                        >
                            <li
                                v-for="o in filteredOptions"
                                :class="[
                                'cursor-pointer select-none relative py-2 pl-8 pr-4',
                                selectedMap[o[idProperty]] ? 'text-white bg-primary' : 'text-gray-900 hover:bg-gray-50'
                            ]"
                                :key="o[idProperty]"
                                @click="select(o)"
                            >
                                <span class="font-normal block truncate">{{ o[labelProperty] }}</span>
                                <div
                                    v-if="selectedMap[o[idProperty]]"
                                    class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="white"
                                    >
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <ul v-if="value.length" class="space-y-3 pt-2">
            <li v-for="v in value" :key="v[idProperty]" class="flex justify-between items-center">
                <div class="text-sm text-gray-900">{{ v[labelProperty] }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import ClickOutside from 'vue-click-outside';
import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';

export default {
    components: { SelectOptionLabel, FormInputArrowIcon },
    directives: { ClickOutside },
    props: {
        editable: {
            type: Boolean,
            'default': true
        },
        placeholder: {
            type: String,
            'default': 'Add item'
        },
        idProperty: {
            type: String,
            'default': 'id'
        },
        labelProperty: {
            type: String,
            'default': 'label'
        },
        value: {
            type: Array,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },
    computed: {
        selectedMap() {
            return this.value.reduce((agg, cur) => ({ ...agg, [cur[this.idProperty]]: true }), {});
        },
        filteredOptions() {
            return !this.q ? this.options : this.options.filter(o => o[this.labelProperty].match(new RegExp(`${this.q}`, 'i')));
        }
    },
    data() {
        return {
            isOpen: false,
            q: ''
        };
    },
    methods: {
        select(o) {
            const { selectedMap, value } = this;

            this.$emit('input', selectedMap[o[this.idProperty]] ? value.filter(v => v[this.idProperty] !== o[this.idProperty]) : [...value, o]);
            this.q = '';
            this.closeDropdown();
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        closeDropdown() {
            this.isOpen = false;
        }
    }
};
</script>
