<template>
  <div>
    <div :class="['w-full flex items-start justify-start flex-col gap-2 pb-2', {
      'border-b': !editable
    }]">
      <label class="text-sm font-medium text-gray-500">{{ label }}</label>
      <div v-if="editable" class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="handleOpenDropDown"
              @input="handleSearch"
              v-model="searchValue"
              :disabled="!editable"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && labelList.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="label in labelList"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': selectedLabels.some(selectedLabel => selectedLabel.id === label.id),
                         'text-gray-900 hover:bg-gray-50': selectedLabels.every(selectedLabel => selectedLabel.id !== label.id)
                }]"
                :key="label.id"
                @click="handleSelectLabel(label)"
            >
              <div class="flex items-center">
                <div>
                  <div v-if="label.avatar" class="flex-shrink-0">
                    <img class="h-5 w-5 rounded-full" :src="label.avatar" alt="">
                  </div>
                  <div v-else>
                    <div class="h-5 w-5 rounded-full bg-gray-200 flex items-center text-10 text-center" :class="label.color">
                      <span class="block w-full text-gray-900"></span>
                    </div>
                  </div>
                </div>
                <div class="ml-3">
                  <span class="font-normal block truncate">{{ label.title }}</span>
                </div>
              </div>
              <span
                  v-if="selectedLabels.some(selectedLabel => selectedLabel.id === label.id)"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                       :fill="selectedLabels.some(selectedLabel => selectedLabel.id === label.id) ? 'white' : 'currentColor'">
                    <path fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                  </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="fieldTitle">
      <SelectOptionLabel
          :label="$t('task.fields.labels')"
          :items="selectedLabels"
      />
    </div>
  </div>
</template>

<script>
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
import SelectOptionLabel from '@/components/SelectOptions/LabelsSelectOption/SelectOptionLabel/SelectOptionLabel';

export default {
  name: 'LabelsSelectOption',
  components: {
    SelectOptionLabel,
    FormInputArrowIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    defaultLabels: {
      type: Array,
      'default': Array
    },
    editable: {
      type: Boolean,
      'default': true
    },
    pageUpdateAction: {
      type: Boolean
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      searchValue: '',
      labelsData: [],
      labelList: [],
      selectedLabels: []
    }
  },
  computed: {
    ...mapGetters(['labels'])
  },
  mounted() {
    !this.isPublicPage ? this.handleGetAssigneeLabels() : this.handleSetDefaultValue();
  },
  watch: {
    labels(newValue) {
      this.labelsData = newValue;
    },
    defaultLabels() {
      this.handleSetDefaultValue();
    },
    labelsData() {
      this.handleSetDefaultValue();
    },
    editable(newValue) {
      !newValue && this.handleSetDefaultValue();
    }
  },
  methods: {
    ...mapActions({
      handleGetAssigneeLabels: 'getLabels'
    }),
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
    },
    handleSearch() {
      this.isOpen = true;

      this.labelList = this.labels.filter(label => {
        if (label.title?.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return label;
        }
      });
    },
    handleSetDefaultValue() {
      this.selectedLabels = this.defaultLabels;
    },
    handleSelectLabel(label) {
      this.isOpen = false;
      const isAssigned = this.defaultLabels.some(item => item.id === label.id);
      this.$emit('handleChangeAssigneeLabels', { label, isAssigned });
    }
  },
  created() {
    this.labelList = this.labels;
  }
}
</script>

<style scoped>

</style>

