<template>
    <div
        v-click-outside="closeDropdown"
        class="relative flex justify-end items-center"
    >
        <button
            @click="handleOpenDropDown"
            type="button"
            class="h-8 bg-white inline-flex items-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none"
        >
            <span class="sr-only">{{ $t('link.openOptions') }}</span>
            <DropDownThreeDots v-if="!isEdit"/>
        </button>

        <div
            v-if="isOpen"
            class="mx-3 origin-top-right absolute top-6 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="project-options-menu-0"
        >
            <div class="py-1" role="none">
                <a
                    @click.prevent="handleEdit"
                    href="#"
                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                >
                    {{ $t('global.buttons.edit') }}
                </a>

                <a
                    @click.prevent="handleOpenDeleteItemModal"
                    href="#"
                    class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                >
                    {{ $t('global.buttons.delete') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
    name: 'EditOrDeleteLink',
    directives: {
        ClickOutside
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        isEdit: {
          type: Boolean,
          required: true
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    components: { DropDownThreeDots },
    methods: {
        handleOpenDropDown() {
            this.isOpen = !this.isOpen;
            this.$emit('handleSelectItem', this.item.id);
        },
        handleEdit() {
            this.$emit('handleEdit', this.item.id);
            this.isOpen = false;
        },
        handleOpenDeleteItemModal() {
            this.$emit('handleOpenDeleteItemModal', this.item.id);
            this.isOpen = false;
        },
        closeDropdown() {
            this.isOpen = false;
            this.$emit('handleSelectItem', '');
        }
    }
};
</script>

<style scoped>

</style>
