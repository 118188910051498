<template>
  <div class="col-span-4 lg:col-span-4 sm:mt-2 md:mt-2  lg:mt-0 flex h-full items-center sm:justify-start md:justify-start lg:justify-end">
    <AddInvoiceModal
        v-if="isOpenAddInvoiceModal"
        :listTitle="listTitle"
        :checkListItemId="item.id"
        :defaultInvoiceLine="item.invoice_line || {}"
        @handleCloseModal="isOpenAddInvoiceModal = !isOpenAddInvoiceModal"
        :showSaveBtn="false"
    />
    <div v-click-outside="closeDropdown" class="flex justify-between">
      <div v-if="checked && !item.invoice_line"
           @click="isOpenAddInvoiceModal = !isOpenAddInvoiceModal"
           class="cursor-pointer px-3 bg-gray-200 rounded-full text-sm text-gray-500 truncate">
        {{ $t('invoice.addToInvoice') }}
      </div>
      <div v-if="item.invoice_line && !item.invoice_line.invoice && checked"
           @click="isOpenAddInvoiceModal = !isOpenAddInvoiceModal"
           :class="item.invoice_line.status.code==='draft'?'bg-gray-200 rounded-full text-sm text-gray-500 truncate':'bg-gray-200 rounded-full text-sm text-white bg-primary truncate'"
           class="cursor-pointer px-3 ">
        {{item.invoice_line.status.name}}
      </div>
      <template v-if="item.invoice_line && item.invoice_line.invoice && checked">
        <div
            v-if="item.invoice_line.invoice.status !== 'sent'"
            @click="isOpenAddInvoiceModal = !isOpenAddInvoiceModal"
            class="cursor-pointer px-3 bg-green-500 rounded-full text-sm text-white truncate">
          {{ $t('invoice.addedToInvoice') }} #{{item.invoice_line.invoice_id}}
        </div>
        <div v-else class="cursor-pointer px-3 bg-gray-300 rounded-full text-sm text-white truncate" :class="{ 'cursor-default': item.invoice_line.invoice.status === 'sent' }">
          {{ $t('global.sent') }} #{{item.invoice_line.invoice_id}}
        </div>
      </template>
      <div @click="handleOpenDropDown" class="cursor-pointer">
        <DropDownThreeDots />
        <div
            v-if="isOpenDropDown"
            class="shadow-lg mx-3 z-50 origin-top-right absolute -right-1 w-48 mt-1 rounded-md 2xl:hover:z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div v-if="checked && (!item.invoice_line || !item.invoice_line.invoice || item.invoice_line.invoice.status !== 'sent')" @click="isOpenAddInvoiceModal = !isOpenAddInvoiceModal" class="py-1">
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              {{ item.invoice_line && Object.keys(item.invoice_line).length ? $t('invoice.invoiceable') : $t('invoice.addInvoice') }}
            </a>
          </div>
          <div class="py-1" role="none">
            <a @click="handleEditCheckListItem" id="edit-list-item" href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{{ $t('checkList.edit') }}</a>
          </div>
          <div @click.prevent="$emit('handleOpenDeleteItemModal', item)" class="py-1">
            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">{{ $t('checkList.delete') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import AddInvoiceModal from '@/components/Modals/AddInvoiceModal/AddInvoiceModal';
import { mapGetters } from 'vuex';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
  name: 'ListItemActions',
  components: {
    DropDownThreeDots,
    AddInvoiceModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    },
    listTitle: {
      type: String,
      'default': ''
    }
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isOpenDropDown: false,
      isOpenAddInvoiceModal: false
    }
  },
  methods: {
    handleOpenDropDown() {
      this.isOpenDropDown = !this.isOpenDropDown;
    },
    closeDropdown() {
      this.isOpenDropDown = false;
    },
    handleEditCheckListItem() {
      this.$emit('handleEditCheckListItem', this.item);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>
  .cursor-default {
    cursor: default;
  }
</style>
