<template>
  <div v-else class="flex items-center justify-start gap-1 mt-2">
    <div class="w-full">
      <FormInput
          :value="title"
          :autofocus="true"
          :placeholder="$t('global.inputs.checkListPlaceholder')"
          @handleInputChange="handleInputChange"
          @handleKeyUp="handleKeyUp"
      />
    </div>
    <MainButton
        :name="$t('global.buttons.add')"
        @clickAction="handleCreateNewItem"
    />
    <MainButton
        :name="$t('global.buttons.cancel')"
        bgStyle="light"
        @clickAction="handleCloseAddNewItemDropDown"
    />
  </div>
</template>

<script>
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';

export default {
  name: 'CreateCheckListItemDropDown',
  components: {
    MainButton,
    FormInput
  },
  props: {
    checkListId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      title: ''
    }
  },
  methods: {
    handleInputChange(inputName, value) {
      this.title = value;
    },
    handleKeyUp(event, inputName) {
      this.handleInputChange(inputName, event.target.value);
      event.keyCode === 13 && this.handleCreateNewItem();
    },
    handleCloseAddNewItemDropDown() {
      this.title = '';
      this.$emit('handleCloseAddNewItemDropDown');
    },
    handleCreateNewItem() {
      if (this.title.length) {
        this.$emit('handleCreateNewItem', { item: this.title, checkListId: this.checkListId });
        this.handleCloseAddNewItemDropDown();
      }
    }
  }
}
</script>

<style scoped>

</style>
