<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true" @click="$emit('handleCloseModal')">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-prose sm:w-full sm:p-6"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ listTitle }}
            </h3>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 mt-5">
          <div class="sm:col-span-1">
            <ProductsOption
                :defaultProduct="product"
                :errorMessage="formErrors.product_id"
                @handleChangeProduct="handleChangeProduct"
            />
          </div>
          <div class="sm:col-span-1">
            <DeliveredBySelectOption
                label="Delivered by"
                :defaultDeliver="deliver || account"
                :errorMessage="formErrors.delivered_by_id||formErrors.delivered_at"
                @handleChangeDeliver="handleChangeDeliver"
            />
          </div>
          <div class="sm:col-span-1">
            <DatePicker
                :defaultValue="form.delivered_at"
                inputName="delivered_at"
                @handleInputFieldChange="form.delivered_at = $event.value"
                :editable="true"/>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('task.fields.description')"
                inputName="description"
                :value="form.description"
                @handleInputChange="handleInputChange"
            />
          </div>
          <div class="sm:col-span-1">
            <FormInput
                label="Quantity"
                inputName="quantity"
                :value="form.quantity"
                :errorMessage="formErrors.quantity"
                type="number"
                @handleBlurChange="handleInputChange"
            />
          </div>
          <div class="sm:col-span-1">
            <FormInput
                label="Unit"
                inputName="unit"
                :value="form.unit"
                :errorMessage="formErrors.unit"
                @handleBlurChange="handleInputChange"
            />
          </div>
          <div class="sm:col-span-1">
            <FormInput
                label="Unit price"
                inputName="unit_price"
                :value="form.unit_price"
                :errorMessage="formErrors.unit_price"
                type="number"
                @handleBlurChange="handleInputChange"
            />
          </div>
          <div class="sm:col-span-1">
            <VatOptions
                :editable="true"
                :selectedVat="selectedVat"
                @selectVat="selectVat"
            />
          </div>
        </div>
        <div class="mt-5 mt-5 grid grid-cols-1">
          <InvoiceTotal :total="total"/>
        </div>
        <div class="mt-5 sm:mt-4 flex justify-end">
          <div class="mr-1">
            <MainButton
                @clickAction="$emit('handleCloseModal')"
                name="Close"
                bgStyle="light"
            />
          </div>
          <div>
            <MainButton
                @clickAction="handleSaveForm"
                name="Save"
                v-if="showSaveBtn"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import ProductsOption from '@/components/SelectOptions/ProductsOption/ProductsOption';
import MembersSelectOption from '@/components/SelectOptions/MembersSelectOption/MembersSelectOption';
import DatePicker from '@/components/DatePicker/DatePicker';
import VatOptions from '@/components/SelectOptions/VatOptions/VatOptions';
import vatOptions from '@/helpers/vatOptions';
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';
import InvoiceTotal from '@/components/Task/InvoiceTotal';
import moment from 'moment';
import DeliveredBySelectOption from '@/components/SelectOptions/DeliveredBySelectOption/DeliveredBySelectOption';

export default {
  name: 'AddInvoiceModal',
  components: {
    DeliveredBySelectOption,
    InvoiceTotal,
    VatOptions,
    DatePicker,
    MembersSelectOption,
    ProductsOption,
    FormInput,
    MainButton
  },
  props: {
    listTitle: {
      type: String,
      'default': ''
    },
    checkListItemId: {
      type: Number,
      required: true
    },
    defaultInvoiceLine: {
      type: Object,
      required: true
    },
    showSaveBtn: {
      type: Boolean,
      'default': true
    }
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters(['account', 'updateInvoiceLineSuccess', 'invoiceLineResponseMessage', 'updateInvoiceLineError', 'invoiceLine'])
  },
  data() {
    return {
      selectedVat: vatOptions[0],
      product: {},
      deliver: null,
      formErrors: {},
      total: {},
      form: {
        product_id: '',
        delivered_at: moment(Date.now()).format('YYYY-M-DD'),
        delivered_by_id: '',
        description: '',
        quantity: 1,
        unit: '',
        unit_price: '',
        vat: vatOptions[0].value,
        isSaveData: false
      }
    }
  },
  watch: {
    updateInvoiceLineSuccess(newVal) {
      if (newVal) {
        this.formErrors = {}
        this.setDefaultValue(this.invoiceLine)
        this.toastMessage(this.invoiceLineResponseMessage, 'success');
        if (this.isSaveData) {
          this.$emit('handleCloseModal');
        }
      }
    },
    updateInvoiceLineError(newValue) {
      if (newValue) {
        const { message, errors } = this.invoiceLineResponseMessage;
        this.formErrors = errors;
        this.toastMessage(message, 'error');
      }
    }
  },
  mounted() {
    this.form.delivered_by_id = this.account.id;
    this.form.deliver = [this.account];
    this.form.description = this.listTitle;
    this.isSaveData = false
    Object.keys(this.defaultInvoiceLine).length && this.setDefaultValue(this.defaultInvoiceLine);
  },
  methods: {
    ...mapActions(['fetchCreateInvoiceLine']),
    setDefaultValue(data) {
      this.form.delivered_by_id = data.delivered_by_id;
      this.form.vat = data.vat;
      this.form.quantity = data.quantity;
      this.form.description = data.description;
      this.form.delivered_at = data.delivered_at;
      this.product = data.product;
      this.form.product_id = data.product_id;
      this.form.unit = data.unit;
      this.form.unit_price = data.unit_price;
      this.selectedVat = vatOptions.find(option => String(option.value) === String(data.vat));
      this.total = this.defaultInvoiceLine.total;
      this.deliver = data.delivered_by;
    },
    handleChangeDeliver(user) {
      this.form.delivered_by_id = user.id;
      this.deliver = user;
      this.removeFormErrorsItem('delivered_by_id');
      this.onchangeSaveForm()
    },
    handleChangeProduct(product) {
      this.form.product_id = product.id;
      this.form.unit_price = product.price;
      this.form.unit = product.unit;
      this.selectedVat = vatOptions.find(option => String(option.value) === String(product.vat));
      this.form.vat = product.vat
      this.form.quantity = 1
      this.removeFormErrorsItem('product_id');
      this.onchangeSaveForm()

    },
    handleInputChange(inputName, value) {
      this.form[inputName] = value;
      this.onchangeSaveForm()
    },
    selectVat(vat) {
      this.form.vat = vat.value;
      this.selectedVat = vat;
      this.onchangeSaveForm()
    },
    onchangeSaveForm() {
      this.isSaveData = false
      this.fetchCreateInvoiceLine({ id: this.checkListItemId, form: this.form });

    },
    handleSaveForm() {
      this.isSaveData = true
      this.fetchCreateInvoiceLine({ id: this.checkListItemId, form: this.form });

    },
    toastMessage(message, type = 'success') {
      this.$toast.clear();
      this.$toast.open({ message, type })
    },
    removeFormErrorsItem(item) {
      this.formErrors[item] = [];
    }
  }
}
</script>

<style scoped>

</style>
