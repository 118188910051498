<template>
  <div>
      <ul v-if="members.length" class="space-y-3 pt-2">
        <li v-for="(member, index) in members"  :key="index" class="flex justify-start">
          <a href="#" class="flex items-center space-x-3">
            <div v-if="member.avatar" class="flex-shrink-0">
              <img class="h-5 w-5 rounded-full" :src="member.avatar" alt="">
            </div>
            <div v-else>
              <div class="h-6 w-6 rounded-full bg-gray-200 flex items-center text-10 text-center">
                <span class="block w-full">{{ (member.name||member.title)  | getNameAbbr }}</span>
              </div>
            </div>
            <div class="mt-1 text-sm text-gray-900">{{ member.name|| member.title }}</div>
          </a>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectOptionLabel',
  props: {
    label: {
      type: String,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
