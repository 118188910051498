<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('main.cloudAttachment') }}</h3>
      </div>
    </div>
    <Attachments
        v-if="files.length"
        :files="files"
        :isPublicPage="isPublicPage"
        @deleteAttachmentFile="$emit('deleteGoogleAttachmentFile', $event)"
    />
    <div v-if="!isPublicPage" class="relative border-b pb-2 pt-2" v-click-outside="closeDropdown">
      <AddNewButton
          :name="$t('global.buttons.add_new')"
          @clickAction="isOpen = !isOpen"
      />
      <DropZone
          :isOpen="isOpen"
          @handleAddGoogleAttachments="handleAddGoogleAttachments"
      />
    </div>
  </div>
</template>

<script>
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';
import ClickOutside from 'vue-click-outside';
import DropZone from '@/components/Main/AttachmentFiles/GoogleCloudInputField/DropZone/DropZone';
import Attachments from '@/components/Main/AttachmentFiles/Attachments/Attachments';

export default {
  name: 'GoogleCloudInputField',
  components: {
    Attachments,
    DropZone,
    AddNewButton
  },
  directives: {
    ClickOutside
  },
  props: {
    isPublicPage: {
      type: Boolean,
      'default': false
    },
    files: {
      type: Array,
      required: true
    },
    ownerId: {
      type: String,
      required: true
    },
    pageUpdateAction: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleAddGoogleAttachments(files) {
      this.$emit('handleAddGoogleAttachments', files);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>

</style>
