<template>
    <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
        <div class="col-span-1">
            <ListingsOptions
                :listings="form.listings"
                :defaultValue="form.listing_id"
                :editable="editable"
                @handleInputFieldChange="$emit('handleInputFieldChange', $event)"
            />
        </div>
        <div class="col-span-1">
            <LabelsSelectOption
                :label="$t('task.fields.labels')"
                :isPublicPage="isPublicPage"
                :defaultLabels="form.labels"
                :labels="form.labels"
                :editable="editable"
                :pageUpdateAction="pageUpdateAction"
                @handleChangeAssigneeLabels="$emit('handleChangeAssigneeLabels', $event)"
            />
        </div>
        <div class="col-span-1">
            <CustomersOptions
                :defaultValue="form.customer_id"
                :defaultCustomerName="form.customerName"
                :editable="editable"
                :isPublicPage="isPublicPage"
                @handleInputFieldChange="form.customer_apartment_id = ''; $emit('handleInputFieldChange', $event)"
            />
        </div>
        <div v-if="form.customer_id && customers.data && customers.data.find(customer => +customer.id === +form.customer_id).apartments.length" class="col-span-1">
            <MultiSelectbox
                :editable="editable"
                v-model="form.apartments"
                :placeholder="$t('customer.fields.apartmentTitle')"
                id-property="id"
                label-property="title"
                :options="customerApartments"
            />
        </div>
        <div class="col-span-1">
            <MembersSelectOption
                :label="$t('task.fields.members')"
                :isPublicPage="isPublicPage"
                :defaultMembers="form.members"
                :editable="editable"
                :pageUpdateAction="pageUpdateAction"
                @handleChangeAssigneeMembers="$emit('handleChangeAssigneeMembers', $event)"
            />
        </div>
        <div class="col-span-1">
            <DatePicker
                :defaultValue="form.due"
                :editable="editable"
                inputName="due"
                @handleInputFieldChange="$emit('handleInputFieldChange', $event)"
            />
        </div>
        <div class="col-span-1" v-if="!isPublicPage">
            <AttachmentInputField
                @handleAddAttachments="$emit('handleAddAttachments', $event)"
                @deleteAttachmentFile="$emit('deleteAttachmentFile', $event)"
                :files="form.files"
                :ownerId="form.id"
                :isPublicPage="isPublicPage"
                :pageUpdateAction="pageUpdateAction"
            />
        </div>
        <div class="col-span-1" v-if="!isPublicPage">
            <GoogleCloudInputField
                @handleAddGoogleAttachments="$emit('handleAddGoogleAttachments', $event)"
                @deleteGoogleAttachmentFile="$emit('deleteGoogleAttachmentFile', $event)"
                :files="form.google_files"
                :isPublicPage="isPublicPage"
                :ownerId="form.id"
                :pageUpdateAction="pageUpdateAction"
            />
        </div>
        <div class="col-span-1">
            <task-links
                :isPublicPage="isPublicPage"
                :label="'Links'"
                :links="form.links"
                :editable="editable"
                :pageUpdateAction="pageUpdateAction"
                @handleAddNewLink="$emit('handleAddNewLink', $event)"
                @handleDeleteLink="$emit('handleDeleteLink', $event)"
                @handleEditLink="$emit('handleEditLink', $event)"
            ></task-links>
        </div>
        <div class="col-span-1" v-if="!isPublicPage">
            <CheckListInputField
                v-if="pageUpdateAction"
                :checklist="form.checklists"
                :isPublicPage="isPublicPage"
                @handleAddNewCheckList="$emit('handleAddNewCheckList', $event)"
            />
        </div>
        <div class="col-span-1" v-if="pageUpdateAction&&!isPublicPage">
            <InvoicingActions
                :taskId="form.id"
                :createdAt="form.createdAt"
                :invoices="form.invoices"
            />
        </div>
    </div>
</template>

<script>
import ListingsOptions from '@/components/SelectOptions/ListingsOptions/ListingsOptions';
import CustomersOptions from '@/components/SelectOptions/CustomersOptions/CustomersOptions';
import AssigneeUsers from '@/components/SelectOptions/AssigneeUsers/AssigneeUsers';
import DatePicker from '@/components/DatePicker/DatePicker';
import AttachmentInputField from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentInputField';
import GoogleCloudInputField from '@/components/Main/AttachmentFiles/GoogleCloudInputField/GoogleCloudInputField';
import MembersSelectOption from '@/components/SelectOptions/MembersSelectOption/MembersSelectOption';
import LabelsSelectOption from '@/components/SelectOptions/LabelsSelectOption/LabelsSelectOption';
import CheckListInputField from '@/components/CheckList/CheckListInputField/CheckListInputField';
import TaskLinks from '@/components/Links/TaskLinks';
import InvoicingActions from '@/components/Task/InvoicingActions/InvoicingActions';
import { mapGetters } from 'vuex';
import MultiSelectbox from '@/components/Main/MultiSelectbox/MultiSelectbox';
import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';

export default {
    name: 'RightAside',
    components: {
        SelectOptionLabel,
        MultiSelectbox,
        InvoicingActions,
        CheckListInputField,
        MembersSelectOption,
        GoogleCloudInputField,
        AttachmentInputField,
        DatePicker,
        AssigneeUsers,
        TaskLinks,
        CustomersOptions,
        ListingsOptions,
        LabelsSelectOption
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            'default': true
        },
        isPublicPage: {
            type: Boolean,
            'default': false
        },
        pageUpdateAction: {
            type: Boolean,
            'default': true
        }
    },
    computed: {
        ...mapGetters(['customers']),
        customerApartments() {
            return this.customers.data.find(customer => +customer.id === +this.form.customer_id).apartments;
        }
    },
    data() {
        return {
            selected: []
        }
    }
};
</script>

<style>

</style>
