<template>
  <div>
    <img :src="require('../../assets/img/google_drive.png')" alt="" width="25">
  </div>
</template>

<script>
export default {
  name: 'GoogleCloudIcon'
}
</script>

<style scoped>

</style>
