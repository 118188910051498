<template>
  <div>
    <div v-if="!editable" class="fieldTitle">
      <SelectOptionLabel
          :label="$t('task.fields.listingOptionTitle')"
          :name="searchValue"
          :isIcon="false"
      />
    </div>
    <div v-else class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ $t('task.fields.listingOptionTitle') }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="handleOpenDropDown"
              @input="handleSearch"
              v-model="searchValue"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && listingsList.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">
            <li v-for="listing in listingsList"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': listing.id === selectedListing.id,
                         'text-gray-900 hover:bg-gray-50': listing.id !== selectedListing.id
                }]"
                :key="listing.id"
                @click="handleSelectListing(listing.id)"
            >
              <span class="font-normal block truncate">{{ listing.name }}</span>
              <span
                  v-if="listing.id === selectedListing.id"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="listing.id === selectedListing.id ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
import ClickOutside from 'vue-click-outside';
import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';

export default {
  name: 'ListingsOptions',
  components: {
    SelectOptionLabel,
    FormInputArrowIcon
  },
  directives: {
    ClickOutside
  },
  props: {
    listings: {
      type: Array,
      required: true,
      'default':() => {
        return []
      }
    },
    defaultValue: {
      type: String
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      listingsList: [],
      selectedListing: {},
      searchValue: '',
      isOpen: false
    }
  },
  watch: {
    defaultValue() {
      this.defaultValue && this.handleSetDefaultValue();
    }
  },
  mounted() {
    this.defaultValue && this.handleSetDefaultValue();
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
      this.listingsList = this.listings;
    },
    handleSetDefaultValue() {
      this.selectedListing = this.listings.find(listing => String(listing.id) === this.defaultValue);
      this.searchValue = this.selectedListing.name;
    },
    handleSearch() {
      this.listingsList = this.listings.filter(listing => {
        if (listing.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return listing;
        }
      });
    },
    handleSelectListing(id) {
      const selectedListing = this.listings.find(listing => listing.id === id);
      this.selectedListing = selectedListing;
      this.searchValue = selectedListing.name;
      this.isOpen = false;
      this.$emit('handleInputFieldChange', { value: String(selectedListing.id), inputName: 'listing_id' });
    }
  }
}
</script>

<style scoped>

</style>
