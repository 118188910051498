<template>
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5">
    <g stroke-linecap="round" stroke-linejoin="round" stroke="#74787f" class="nc-icon-wrapper">
      <polyline data-color="color-2" points="7 9 11 13 22 2" fill="none" stroke-miterlimit="10"></polyline>
      <polyline points="20 11 20 21 2 21 2 3 15 3" fill="none" stroke="#74787f"
                stroke-miterlimit="10"></polyline>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CheckListIcon'
}
</script>

<style scoped>

</style>
