<template>
  <section>
    <DeleteItemModal
        v-if="showModal"
        :title="$t('checkList.deleteCheckListModal.title')"
        :class="modal-card"
        :description="$t('checkList.deleteCheckListModal.description')"
        @handleCloseModal="showModal = false"
        @handleConfirm="handleDeleteItem"
    />
    <div class="py-5 border-t">
      <h2 class="text-lg font-medium text-gray-900">{{ $t('checkList.checklists') }}</h2>
    </div>
    <div v-if="checkListData.length">
      <ul class="w-full">
        <li
            v-for="(list, index) in checkListData"
            :key="index"
            class="w-full flex justify-between items-center relative">
          <CheckListItem
              :list="list"
              :selectedItem="String(selectedItem)"
              :isPublicPage="isPublicPage"
              @handleUpdateCheckList="$emit('handleUpdateCheckList', $event)"
              @handleSetSelectedItem="handleSetSelectedItem"
              @openDeleteCheckListModal="openDeleteCheckListModal"
              @handleDeleteCheckListItem="$emit('handleDeleteCheckListItem', $event)"
              @handleCreateNewCheckListItem="$emit('handleCreateNewCheckListItem', $event)"
              @handleUpdateCheckListItem="$emit('handleUpdateCheckListItem', $event)"
              @handleEditCheckListItemStatus="$emit('handleEditCheckListItemStatus', $event)"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import CheckListItem from '@/components/CheckList/CheckListsSection/CheckListItem/CheckListItem';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { mapGetters } from 'vuex';

export default {
  name: 'CheckListsSection',
  components: {
    CheckListItem,
    DeleteItemModal
  },
  props: {
    checkLists: {
      type: Array,
      required: true
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      selectedItem: '',
      showModal: false,
      checkListData: []
    }
  },
  computed: {
    ...mapGetters([
      'invoiceLine',
      'updateInvoiceLineSuccess',
      'invoiceLineResponseMessage'
    ])
  },
  watch: {
    checkLists(newValue) {
      this.checkListData = newValue;
    },
    updateInvoiceLineSuccess(newValue) {
      if (newValue) {
        const { item_id } = this.invoiceLine;
        this.checkListData.filter((checkList) => {
          checkList.items.filter((list) => {
            if (list.id === item_id) {
              list.invoice_line = this.invoiceLine;
            }
          })
        });
      }
    }
  },
  mounted() {
    this.checkListData = this.checkLists;
  },
  methods: {
    handleSetSelectedItem(id) {
      this.selectedItem = id;
    },
    openDeleteCheckListModal(id) {
      this.showModal = true;
      this.selectedItem = id
    },
    handleDeleteItem() {
      this.showModal = false;
      this.$emit('handleDeleteCheckList', this.selectedItem);
    }
  }
}
</script>

<style scoped>

</style>
